
import React from "react"

import UtilityLayout from "../components/utility-layout"
import Calendar from "../images/xa-calendar-light.svg"
import Logo from "../images/xa-pink-logo.svg"
import Follow from "../images/follow-me.svg"

import html2canvas from "html2canvas"

function SocialCards({ }) {
  return (
    <UtilityLayout>

      <div>

        <h2 className="mb-2">Twitter card</h2>
        <div id="twitter-card" className="bg-sky-900 w-[900px] h-[300px] p-5">
          <div className="flex w-[850px] justify-between px-5">
            <img src={Logo} width={400} />
            <span className="flex items-center text-white px-1">
              <img src={Calendar} width={64} />
              <span id="t1" className="flex flex-col leading-none mt-2 gap-0">
                <span className="mt-0 p-0">Next 3-day online workshop:</span>
                <span id="t2" className="text-4xl font-bold p-0">23.05.2022</span>
              </span>
            </span>
          </div>
          <div className="flex w-[850px] justify-center mt-10 ml-12">
            <p className="text-3xl font-bold text-center">Learn <strong className="text-white">DevOps</strong> principles and tools</p>
          </div>
          <div className="flex w-[850px] justify-end mt-13">
            <img src={Follow} width={150} />
          </div>
        </div>
        <div className="flex w-[900px] justify-end mt-2">
          <button className="btn-primary" onClick={() => exportTwitterCard("twitter-card.png")}>
            Capture Image
          </button>
        </div>

      </div>

    </UtilityLayout>
  )
}

async function exportTwitterCard(imageFileName) {
  // NOTE: Ugly hack of translating some elements to produce better image. 
  // Most likely a bug in html2canvas library.
  const t1 = document.getElementById("t1")
  const t1Margin = t1.style.marginTop
  t1.style.marginTop = "-16px"
  const t2 = document.getElementById("t2")
  const t2Margin = t2.style.marginTop
  t2.style.marginTop = "-12px"
  await new Promise(resolve => setTimeout(resolve, 1000))
  await exportAsImage("twitter-card", imageFileName)
  t1.style.marginTop = t1Margin
  t2.style.marginTop = t2Margin
}

async function exportAsImage(elementId, imageFileName) {
  const element = document.getElementById(elementId)
  const canvas = await html2canvas(element)
  const image = canvas.toDataURL("image/png", 3.0)
  downloadImage(image, imageFileName)
}

function downloadImage(blob, fileName) {
  const fakeLink = window.document.createElement("a")
  fakeLink.download = fileName
  fakeLink.href = blob
  document.body.appendChild(fakeLink)
  fakeLink.click()
  document.body.removeChild(fakeLink)
  fakeLink.remove()
}

export default SocialCards
